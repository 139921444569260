import { LayoutConfigBuilder } from 'src/app/layouts/default-layout/builders/layout-config.builder';
import { LayoutConfigPresets } from 'src/app/layouts/default-layout/models/layout-structures.models';

export enum IltsRoutesConfig {
	LIVE_TRAININGS_BASE_URL = 'trainings',
	SCHEDULE_SESSIONS_URL_SEGMENT = 'schedule-sessions',
	SCHEDULE_SESSION_IN_PERSON_URL_SEGMENT = 'schedule-sessions/in-person',
	SCHEDULE_SESSION_VIRTUAL_URL_SEGMENT = 'schedule-sessions/virtual',
	CHOOSE_SESSION_TYPE_URL_SEGMENT = 'choose-session-type',
	EDIT_TRAINING_URL_SEGMENT = 'edit',
	NEW_TRAINING_URL_SEGMENT = 'new',
	MARK_ATTENDANCE_URL_SEGMENT = 'mark-attendance',
	OVERVIEW_TAB_URL_SEGMENT = 'overview',
	SESSIONS_TAB_URL_SEGMENT = 'sessions',
	SESSION_DETAILS_URL_SEGMENT = 'session-details',
	SESSION_SERIES_URL_SEGMENT = 'session-series',
	REGISTERED_TAB_URL_SEGMENT = 'registered',
	WAITLISTED_TAB_URL_SEGMENT = 'waitlisted',
	PENDING_REGISTRATIONS_TAB_URL_SEGMENT = 'pending-registrations',
	TRAINING_ID_PARAM = ':trainingId',
	SINGLE_SESSION_URL = 'sessions/:sessionId',
	SINGLE_SESSION_OVERVIEW_URL = 'sessions/:sessionId/overview',
	SINGLE_SESSION_REGISTRANTS_URL = 'sessions/:sessionId/registrants',
	SINGLE_SESSION_EDIT_URL = 'sessions/:sessionId/edit',
	SINGLE_SESSION_MARK_ATTENDANCE_URL = 'sessions/:sessionId/mark-attendance',
	SINGLE_SESSION_SERIES_MARK_ATTENDANCE_URL = 'sessions/:sessionId/session-details/:sessionDetailsId/mark-attendance',
	SINGLE_SESSION_DOWNLOAD_ATTENDANCE_ROSTER_URL = 'sessions/:sessionId/mark-attendance/attendance-roster',
	SINGLE_SESSION_SERIES_URL = 'session-series/:sessionId',
	SINGLE_SESSION_SERIES_SESSION_URL = 'session-series/:sessionId/sessions/:sessionDetailsId'
}

export const ILTsRouteTitles = {
	addTrainingSessions: 'Add ILT session',
	liveTrainingsHomePage: 'tabs.live_learning',
	editTraining: 'events.edit_training',
	trainingOverview: 'lup.live_trainings.overview',
	trainingLearners: 'lup.live_trainings.registrants',
	trainingSessions: 'lup.live_trainings.sessions'
};

export const settingsRoutesLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.minimal)
	.setBodyCssClasses(['bg--white'])
	.setContentCssClasses(['wrap--no-subheader', 'wrap--action-footer'])
	.setHeaderCssClasses(['header--no-scroll'])
	.setFooterCssClasses(['footer-white'])
	.setAdminWrapCssClasses(['admin-wrap--no-sidenav'])
	.build();

export const redesignedSettingsRoutesLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.minimal)
	.setBodyCssClasses(['bg--gray-lighter'])
	.setContentCssClasses(['wrap--no-subheader', 'wrap--action-footer'])
	.setHeaderCssClasses(['header--no-scroll'])
	.setFooterCssClasses(['footer-white'])
	.setAdminWrapCssClasses(['admin-wrap--no-sidenav'])
	.build();

export const liveTrainingsHomePageLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.default)
	.showDefaultSubHeader(false)
	.setBodyCssClasses(['bg--gray-lighter'])
	.setContentCssClasses(['wrap--no-subheader'])
	.build();

export const markAttendanceLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.minimal)
	.setBodyCssClasses(['bg--white'])
	.setContentCssClasses(['wrap--no-subheader', 'wrap--action-footer'])
	.setHeaderCssClasses(['header--no-scroll'])
	.setFooterCssClasses(['footer-white'])
	.setAdminWrapCssClasses(['admin-wrap--no-sidenav'])
	.build();
