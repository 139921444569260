import * as Sentry from '@sentry/angular';

export function makeFile(file: File | Blob, fileName: string): File {
	try {
		return new File([file], fileName);
	} catch {
		// file constructor does not work in IE 11 and no polyfills for this one
		const fileAsBlob = new Blob([file]) as any;
		fileAsBlob.name = fileName;
		fileAsBlob.lastModifiedDate = new Date();
		return <File>fileAsBlob;
	}
}

/**
 * Converts an object to a base64-encoded string.
 *
 * @param object - The object to be converted to a base64 string.
 * @param onError - The function to call if an error occurs during the conversion.
 * @returns The base64-encoded string representation of the object, or an empty string if an error occurs.
 */
export function objectToBase64String(object: unknown, onError = Sentry.captureException): string {
	if (!object) return;
	try {
		return btoa(encodeURIComponent(JSON.stringify(object)));
	} catch (err) {
		onError?.(err);
		return '';
	}
}

/**
 * Converts a base64-encoded string back to an object.
 *
 * @param base64String - The base64 string to be converted back to an object.
 * @param onError - The function to call if an error occurs during the conversion.
 * @returns The object represented by the base64 string, or an empty object if an error occurs.
 */
export function base64StringToObject<T = unknown>(base64String: string, onError = Sentry.captureException): T {
	if (!base64String) return;
	try {
		return JSON.parse(decodeURIComponent(atob(base64String))) as T;
	} catch (err) {
		onError?.(err);
		return {} as T;
	}
}
